<template>
	<div class="news-article" v-if="article">
		<!-- Loader -->
		<v-row justify="center" align="center" v-if="isLoading">
			<v-col cols="12" class="text-center">
				<div class="spinner-grow spinner-border"></div>
			</v-col>
		</v-row>

		<!-- Content -->
		<v-row v-else>
			<v-col cols="12">
				<!-- Header Image -->
				<div v-if="article.image && article.image !== ''" class="header-image">
					<img
						:src="imageLoaded ? article.image : defaultHeader"
						@load="imageLoaded = true"
						@error="imageLoaded = false"
						:alt="article.title"
						class="header-img"
					/>
					<div class="head-image-text" style="opacity: 80%">
						NEXT LEVEL NEWS
					</div>
				</div>
			</v-col>

			<v-col cols="12">
				<!-- Context Container -->
				<div class="context-container">
					<v-row>
						<!-- Title and Date -->
						<v-col cols="12" md="8">
							<h2
								class="context-title"
								v-if="article.title && article.title !== ''"
							>
								{{ article.title }}
							</h2>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<div class="line-yellow"></div>
						</v-col>
					</v-row>
					<v-row class="pt-0 mt-0 d-flex align-items-center justify-space-between">
						<!-- Date -->
						<v-col cols="auto" class="d-flex align-items-center">
							<p class="date-created">
							{{ getTimeAgo(article.createdAt) }}
							</p>
						</v-col>
						<v-spacer></v-spacer>
						<!-- Share Button -->
						<v-col v-if="shareLink" cols="auto" class="d-flex justify-end">
							<ShareButton :link="shareLink" />
						</v-col>
					</v-row>
					<v-row>
						<!-- Tags -->
						<v-col cols="8" md="6" class="d-flex justify-content-end">
							<div class="tags">
								<span v-for="tag in article.tags" :key="tag" class="tag">{{
									tag
								}}</span>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<!-- Author Info -->
						<v-col cols="12">
							<div class="author-container" v-if="article.author">
								<div
									class="profile-picture-container"
									v-if="article.author.image && article.author.image !== ''"
								>
									<img
										class="profile-picture"
										:src="article.author.image"
										alt="Author's Name"
									/>
								</div>
								<div class="information">
									<p
										class="auth-name"
										v-if="
											article?.author.fullName &&
											article?.author.fullName !== ''
										"
									>
										{{ article?.author?.fullName }}
									</p>
									<p
										class="auth-designation"
										v-if="
											article?.author?.designation &&
											article?.author?.designation !== ''
										"
									>
										{{ article?.author?.designation }}
									</p>
								</div>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<!-- News Content -->
						<v-col cols="12">
							<div class="mt-5" v-if="article.text && article.text !== ''">
								<p class="context-desc" v-html="article.text"></p>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<!-- Similar News Article -->
						<v-col cols="12" v-if="similarArticles.length > 0">
							<h2 class="similar-news-heading mb-2">Similar News Articles</h2>
							<v-row class="news-items similar-news">
								<v-col
									v-for="similarArticle in similarArticles.slice(0, 4)"
									:key="similarArticle.id"
									cols="12"
									sm="6"
									md="3"
								>
									<news-card
										:key="similarArticle.id"
										:image="similarArticle.imageFilename[0]"
										:title="similarArticle.title"
										:icon="nlse_icon"
										:link="similarArticle.id"
										:recommendedNewsArticle="true"
									/>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { getNewsDetailById } from "@/services/newsUtils/newsArticleService";
import moment from "moment";
import NewsCard from "../Components/NLSE/News/NewsCard.vue";
import { fetchSimilarArticles } from "@/services/newsUtils/similarArticlesService"; // Update the path
import ShareButton from "../Components/NLSE/Share/ShareButton.vue";
export default {
	components: {
		NewsCard,
		ShareButton,
	},
	data() {
		return {
			article: {},
			defaultHeader: require("@/assets/images/newsroom/thumbnail/newsThumbnail_header.jpg"), // Path to the placeholder image
			similarArticles: [],
			isLoading: true,
			imageLoaded: false,
			nlse_icon: require("@/assets/images/icons/white icons/Group 232.svg"),
			shareLink: "",
		};
	},
	methods: {
		/* Get time in 11 Days Ago Format */

		// getTimeAgo(date) {
		//  const timeElapsed = moment(date).fromNow();
		//  return timeElapsed.endsWith("ago")
		//      ? timeElapsed
		//      : timeElapsed.replace(/(about|in)\s/, "") + " ago";
		// },

		/* Get time in Number Format */

		getTimeAgo(date) {
			const formattedDate = moment(date).format("M/D/YYYY");
			return formattedDate;
		},
		getBackgroundImageStyle(image) {
			return image ? { backgroundImage: `url(${image})` } : {};
		},
	},
	async created() {
		const articleId = this.$route.params.id;
		this.article = await getNewsDetailById(articleId);
		this.isLoading = false;
		this.shareLink = `${process.env.VUE_APP_SOCIAL_MEDIA_URL}/article/${articleId}`;
		// console.log(this.article);

		if (this.article && this.article.tags) {
			// console.log(this.article.tags);
			const similarArticles = await fetchSimilarArticles(
				this.article.tags,
				this.article.id
			);
			this.similarArticles = similarArticles;
			// console.log(this.similarArticles);
		}
	},
};
</script>

<style>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.news-article {
	background-color: #fff;
	font-family: 'Inter', sans-serif !important;
	font-weight: 400; 
	color: #040505;
	padding-top: 3vh;
}

p {
}

h1 {
	text-align: center;
	color: #333;
	font-size: 2em;
}

img {
	display: block;
	margin: 0 auto;
}

.header-image {
	width: 100%;
	aspect-ratio: 16/9;
	max-height: 60vh !important;
	/* filter: grayscale(60%); */
	opacity: 1;
	background-position: top;
	padding-bottom: 0px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	background-color: rgba(0, 0, 0, 0.14);
}

.head-image-text {
	/* top: 60vh;
    position: relative; */
	background-color: black !important;
	width: 100% !important;
	margin: 0 !important;
}
p {
	font-size: 1.2em;
	color: #666;
}

.date-created {
	text-align: left;
	font-size: 1.4rem;
	/* font-family: Arial, Helvetica, sans-serif; */
	letter-spacing: 0px;
	color: #808080;
	opacity: 1;
}

.profile-picture-container {
	width: 10vh; /* Adjust as needed */
	height: 10vh; /* Adjust as needed */
	border-radius: 50%; /* Makes the container circular */
	overflow: hidden; /* Hides anything outside the container's boundary */
	margin-right: 10px; /* Adds space between the image and the text */
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-picture {
	max-width: 100%;
	object-fit: contain; /* Ensures that the image covers the entire container without distortion */
}

.auth-name {
	text-align: left;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	letter-spacing: 0px;
	color: #040505;
	font-size: 1.2rem;
	font-weight: 600;
}

.auth-designation {
	text-align: left;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	letter-spacing: 0px;
	color: #808080;
	font-size: 1.2rem;
}

.author-container {
	display: flex; /* Aligns children horizontally */
	align-items: center; /* Centers children vertically */
}

.information .auth-name,
.information .auth-designation {
	margin: 0; /* Removes default paragraph margins */
}

/* Content */
/* .news-content {
    text-align: left;
    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #040505;
    opacity: 1;
} */

.similar-news-heading {
	text-align: left;
	letter-spacing: 0px;
	color: #040505;
	opacity: 1;
	font-size: 1.2rem;
	font-weight: 600;
}

.news-container {
	height: 200px;
	/* width: 200px; */
	padding: 10px;
	display: flex;
}

.news-card > img {
	opacity: 1 !important;
}

.news-card {
	margin-right: 10px !important;
}

/* Tags */

.article-details {
	/* Adjust styling for the article details section */
	flex: 1;
	padding-right: 20px; /* Add spacing between article details and tags */
}

.article-tags {
	/* Adjust styling for the tags section */
	flex: 0.3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.date-and-tags {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	justify-content: space-between;
}
.tags {
	display: flex;
	flex-wrap: wrap;
}

.tag {
	background-color: #f2f2f2;
	padding: 4px 8px;
	margin: 2px;
	border-radius: 4px;
	min-width: 100px;
	font-size: 1.2rem;
	font-weight: bold;
	color: #808080;
	text-transform: uppercase;
}

.similar-news p.title {
	font-size: clamp(0.5rem, 2vw, 1rem) !important;
}

/* Converting the div header background image to the image tag */

.header-image {
	width: 100%;
	aspect-ratio: 16/9;
	max-height: 60vh !important;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative; /* Allows absolute positioning of the text inside */
}

.header-img {
	width: 100%; /* Make the image take full width of the container */
	height: 100%; /* Make the image take full height of the container */
	object-fit: cover; /* Cover the container without distorting the image */
	object-position: center; /* Center the image within the container */
}

.head-image-text {
	position: absolute; /* Absolute position the text over the image */
	bottom: 0;
	width: 100%;
	text-align: center;
	background-color: rgba(
		0,
		0,
		0,
		0.5
	); /* Slightly darken the overlay text background */
	color: white;
	padding: 0.5em 0; /* Add some padding to the text */
}
</style>
